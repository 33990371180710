<template>
    <zw-sidebar @shown="shown"
                :title="payload.id ? $t('common.form.title.editTodo')  + ' (' + payload.id + ')' : $t('common.form.title.createTodo')"
    >
        <b-overlay :show="loading" no-wrap></b-overlay>
        <validation-observer tag="div" ref="observer">
            <div v-if="!loading">
                <b-row>
                    <b-col :cols="form.todo==true ? 6: 10">
                        <b-row>
                            <b-col cols="4"></b-col>
                            <b-col cols="4">
                                <b-form inline>
                                    <div>{{ $t('common.event.label.todo') }}:</div>
                                    <zw-switch-group v-model="form.todo"
                                                     :label-prefix="'common.event.label.'"
                                                     disable-label
                                                     name="todo"
                                                     class="ml-2"
                                                     :falseValue="false"
                                                     :trueValue="true"
                                                     @input="todoEnabled"
                                    />
                                </b-form>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="4">
                                <zw-select-group v-model="form.user_id"
                                                 :options="users"
                                                 name="todo.user"
                                                 text-field="fullname"
                                                 value-field="id"
                                                 validate="required"
                                                 size="s"
                                ></zw-select-group>
                            </b-col>
                            <b-col cols="4">
                                <zw-select-group v-model="form.status"
                                                 :options="getTodoStatues()"
                                                 name="todo.status"
                                                 validate="required"
                                                 size="s"
                                ></zw-select-group>
                            </b-col>
                            <b-col cols="4" v-if="form.status=='done'">
                                <zw-new-date-group v-model="form.finished_at"
                                                   name="todo.finished_at"
                                                   format="DD.MM.YYYY"
                                                   size="s"
                                ></zw-new-date-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="4">
                                <zw-select-group v-model="form.action"
                                                 :options="getTodoActions()"
                                                 validate="required"
                                                 name="todo.action"
                                                 size="s"
                                ></zw-select-group>
                            </b-col>
                            <b-col cols="4">
                                <zw-select-group v-model="form.sub_action_id"
                                                 :options="getTodoSubActions()[form.action]"
                                                 name="todo.sub_action"
                                                 size="s"
                                ></zw-select-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6">
                                <zw-date-select-group v-model="form.deadline"
                                                      name="todo.deadline"
                                                      validate="required"
                                                      size="s"
                                />
                            </b-col>
                            <b-col cols="2" class="pt-4">
                                <zw-new-time-group v-model="form.deadline_time"
                                                   name="deadline_time"
                                                   disable-label
                                                   size="s"
                                ></zw-new-time-group>
                            </b-col>
                            <b-col cols="12">
                                <zw-input-group v-model="form.title"
                                                name="todo.title"
                                                validate="required"
                                                size="s"
                                ></zw-input-group>
                            </b-col>
                            <b-col cols="12">
                                <zw-textarea-group v-model="form.description"
                                                   name="todo.description"
                                />
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col :cols="form.todo==true ? 6: 2">
                        <b-row>
                            <b-col cols="4">&nbsp;</b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12">
                                <template v-if="form.todo==true">
                                    <b-row>
                                        <b-col cols="4">
                                            <zw-select-group v-model="form.todo_user_id"
                                                             :options="users"
                                                             name="todo.user"
                                                             text-field="fullname"
                                                             value-field="id"
                                                             size="s"
                                            ></zw-select-group>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col cols="4">
                                            <zw-select-group
                                                v-model="form.todo_action"
                                                :options="getTodoActions()"
                                                name="todo.action"
                                                validate="required"
                                                size="s"
                                            ></zw-select-group>
                                        </b-col>
                                        <b-col cols="4">
                                            <zw-select-group
                                                v-model="form.todo_sub_action"
                                                :options="getTodoSubActions()[form.todo_action]"
                                                name="todo.sub_action"
                                                size="s"
                                            ></zw-select-group>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col cols="6">
                                            <zw-date-select-group v-model="form.todoDate"
                                                                  name="todo.deadline"
                                                                  size="s"
                                            />
                                        </b-col>
                                        <b-col cols="2" class="pt-4">
                                            <zw-new-time-group v-model="form.todoTime"
                                                               name="deadline_time"
                                                               disable-label
                                                               size="s"
                                            ></zw-new-time-group>
                                        </b-col>
                                        <b-col cols="12">
                                            <zw-input-group v-model="form.todo_title"
                                                            name="todo.title"
                                                            size="s"
                                            />
                                        </b-col>
                                        <b-col cols="12">
                                            <zw-textarea-group v-model="form.todo_description" name="todo.description"/>
                                        </b-col>
                                    </b-row>
                                </template>

                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>

                <div class="form-actions">
                    <b-row>
                        <b-col sm="12" class="text-xs-center">
                            <b-row align-h="end">
                                <b-col sm="3" class="text-sm-right">
                                    <b-button block @click="onSubmit()" variant="primary">
                                        {{ $t('common.button.save') }}
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </validation-observer>
    </zw-sidebar>

</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import ZwNewDateGroup from "../../components/ZwNewDateGroup";
import moment from "moment";
import todos from "@/bundles/erika_common_bundle/mixins/todos";
import ZwDateSelectGroup from "@/components/ZwDateSelectGroup.vue";

export default {
    components: {ZwDateSelectGroup, ZwNewDateGroup},
    mixins: [commonSave, todos],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            users: [],
            defaultForm: {
                user_id: null,
                status: 'todo',
                deadline: moment().format('YYYY-MM-DD'),
                deadline_time: '12:00',
                kva_id: null,
                contract_id: null,
                customer_id: null,
                todo: 0,
                todoDate: moment().add('1', 'day').format('YYYY-MM-DD'),
                todoTime: '12:00',
                finished_at: null,
            },
            show_email_settings: false,
            form: {
                todo: 0,
                todo_custom_date: moment().format('YYYY-MM-DD'),
                todoDate: 'tomorrow',
            }
        }
    },
    methods: {
        ...mapGetters('Users', ['getUsersList']),
        ...mapGetters('Todo', ['getTodo']),
        ...mapGetters('CommonData', ['getTodoStatues', 'getTodoActions', 'getTodoSubActions', 'getMe', 'getEventsDateOptions']),
        shown() {
            this.$store.dispatch('Users/fetchUsersList').then(() => {
                let me = this.getMe();
                if (this.payload.id) {
                    this.$store.dispatch('Todo/fetchTodo', this.payload.id)
                        .then(() => {
                            this.form = {...this.defaultForm, ...this.getTodo()}
                            let hoursOffset = moment().utcOffset() / 60
                            this.form.deadline_time = moment(this.getTodo().deadline).add(hoursOffset, 'hour').format('HH:mm')
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {

                    this.form = {...this.defaultForm}
                    this.form.kva_id = this.payload.kva_id ?? null
                    this.form.contract_id = this.payload.contract_id ?? null
                    this.form.customer_id = this.payload.customer_id ?? null
                    this.form.user_id = me.id
                    this.loading = false
                }
                var show_email_settings = false;
                Object.keys(me).forEach(function (key) {
                    if (key.indexOf('smtp_') >= 0 && me[key] == null) {
                        show_email_settings = true;
                    }
                });
                this.show_email_settings = show_email_settings;

                this.users = this.getUsersList()

            })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    let [hours, minutes] = this.form.todoTime.split(':').map(Number);
                    hours -= moment().utcOffset() / 60

                    let [deadline_hours, deadline_minutes] = this.form.deadline_time.split(':').map(Number);
                    deadline_hours -= moment().utcOffset() / 60

                    const deadlineDateTime = moment(this.form.deadline).set({
                        hour: deadline_hours,
                        minute: deadline_minutes,
                        second: 0
                    });

                    const todoDeadlineDateTime = moment(this.form.todoDate).set({
                        hour: hours,
                        minute: minutes,
                        second: 0
                    });

                    this.$store.dispatch('Todo/saveTodo', {
                        id: this.form.id,
                        user_id: this.form.user_id,
                        status: this.form.status,
                        action: this.form.action,
                        sub_action_id: this.form.sub_action_id,
                        deadline: deadlineDateTime.format('YYYY-MM-DD HH:mm:ss'),
                        finished_at: this.form.finished_at,
                        title: this.form.title,
                        description: this.form.description,
                        kva_id: this.form.kva_id,
                        contract_id: this.form.contract_id,
                        customer_id: this.form.customer_id,
                        todo: this.form.todo,
                        todoDate: todoDeadlineDateTime.format('YYYY-MM-DD HH:mm:ss'),
                        todo_title: this.form.todo_title,
                        todo_user_id: this.form.todo_user_id,
                        todo_action: this.form.todo_action,
                        todo_sub_action: this.form.todo_sub_action,
                        todo_description: this.form.todo_description,
                    })
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.commonValidation()
                }
            })
        },
        showEmailSettings() {
            this.$router.push({path: '/settings/users', query: {me: '1'}})
        },
        todoEnabled(val) {
            if (val) {
                this.form.todo_action = this.form.action
                this.form.todo_title = this.form.title

                let me = this.getMe();

                this.form.todo_user_id = me.id
            }
        }
    }
}
</script>